<template>
    <div class="translate-input" :class="[{ 'extended': extended }, type]">
        <div @click="extended = false" class="overlay"></div>

        <div class="input-container">
            <b-form-group :label="label|tr" :class="{ 'inline': inline, 'mb-0': noMargin }">
                <template v-if="tr(label) || $slots['label-info']" #label>
                    {{label|tr}}
                    <slot name="label-info"></slot>
                </template>
                <b-input-group :size="size">
                    <slot name="prepend"></slot>
                    <b-input-group-text @click="changeLanguage" class="flag-container" v-b-tooltip :title="'View other language|Voir l\'autre langue'|tr">
                        <b-img v-if="lang === 'fr'" :src="getPublicIcon('flags/france')"/>
                        <b-img v-else :src="getPublicIcon('flags/united-states')"/>
                        <i class="fas fa-caret-down"></i>
                    </b-input-group-text>
                    <template v-if="type === 'textarea'">
                        <b-textarea @click="extended = true" v-if="lang === 'fr'" :value="valueFR" autocomplete="off"
                                    @input="change('fr', $event)" @keyup.ctrl.enter="extended = false"
                                    @change="$emit('change', $event)" :placeholder="placeholderFR" :readonly="readonly"
                                    no-resize :disabled="disabled" :style="{ 'min-height': extended ? expandedHeight: height }" :state="isFRValid" :maxlength="maxlength"></b-textarea>
                        <b-textarea @click="extended = true" v-else :value="valueEN" autocomplete="off"
                                    @input="change('en', $event)" @keyup.ctrl.enter="extended = false"
                                    @change="$emit('change', $event)" :placeholder="placeholderEN" :readonly="readonly"
                                    no-resize :disabled="disabled" :style="{ 'min-height': extended ? expandedHeight: height }" :state="isENValid" :maxlength="maxlength"></b-textarea>
                    </template>
                    <template v-else>
                        <b-input @click="extended = true" :size="size" v-if="lang === 'fr'" :value="valueFR" autocomplete="off"
                                 @input="change('fr', $event)" @keyup.enter="extended = false" :readonly="readonly"
                                 @change="$emit('change', $event)" :placeholder="placeholderFR" :state="isFRValid" :maxlength="maxlength"
                                 :disabled="disabled"></b-input>

                        <b-input @click="extended = true" :size="size" v-else :value="valueEN" autocomplete="off"
                                 @input="change('en', $event)" @keyup.enter="extended = false" :readonly="readonly"
                                 @change="$emit('change', $event)" :placeholder="placeholderEN" :state="isENValid" :maxlength="maxlength"
                                 :disabled="disabled"></b-input>
                    </template>
                    <slot name="append"></slot>
                    <slot name="autocomplete" v-if="autoCompletion">
                        <b-btn class="w-auto" @click="showAutoCompletion" v-model="autoCompletionVisible" :title="'Browse suggestions|Parcourir les suggestions'|tr">
                            <i class="fas fa-caret-down"></i>
                        </b-btn>
                    </slot>

                    <b-btn v-if="autoTranslate && valueFR" @click.stop="translateWithGoogle(valueFR, 'fr', 'en')" variant="light" :title="'Generate translation|Générer la traduction'|tr" v-b-tooltip class="translate-btn">
                        <i class="fas fa-language"></i>
                    </b-btn>
                </b-input-group>

                <div class="form-help">
                    <slot name="form-help"></slot>
                </div>
            </b-form-group>

            <b-form-group class="dropdown-menu" :class="{ 'show': extended }">
                <label v-tr>Translation|Traduction</label>
                <b-input-group :size="size">
                    <b-input-group-text @click="changeLanguage" class="flag-container">
                        <b-img v-if="lang === 'en'" :src="getPublicIcon('flags/france')"/>
                        <b-img v-else :src="getPublicIcon('flags/united-states')"/>
                        <i class="fas fa-caret-down"></i>
                    </b-input-group-text>
                    <template v-if="type === 'textarea'">
                        <b-textarea v-if="lang === 'en'" :value="valueFR" autocomplete="off"
                                    @input="change('fr', $event)" @keyup.ctrl.enter="extended = false"
                                    @change="$emit('change', $event)" :placeholder="placeholderFR" :readonly="readonly"
                                    no-resize :disabled="disabled" :style="{ 'min-height': extended ? expandedHeight: height }" :state="isFRValid"></b-textarea>

                        <b-textarea v-else :value="valueEN" autocomplete="off"
                                    @input="change('en', $event)" @keyup.ctrl.enter="extended = false"
                                    @change="$emit('change', $event)" :placeholder="placeholderEN" :readonly="readonly"
                                    no-resize :disabled="disabled" :style="{ 'min-height': extended ? expandedHeight: height }" :state="isENValid"></b-textarea>
                    </template>
                    <template v-else>
                        <b-input v-if="lang === 'en'" :size="size" :value="valueFR" autocomplete="off"
                                 @input="change('fr', $event)" @keyup.enter="extended = false" :readonly="readonly"
                                 @change="$emit('change', $event)" :placeholder="placeholderFR" :state="isFRValid"
                                 :disabled="disabled"></b-input>

                        <b-input v-else :value="valueEN" :size="size" autocomplete="off"
                                 @input="change('en', $event)" @keyup.enter="extended = false" :readonly="readonly"
                                 @change="$emit('change', $event)" :placeholder="placeholderEN" :state="isENValid"
                                 :disabled="disabled"></b-input>
                    </template>

                    <b-btn v-if="autoTranslate && valueEN" @click.stop="translateWithGoogle(valueEN, 'en', 'fr')" variant="secondary" :title="'Generate translation|Générer la traduction'|tr" class="translate-btn">
                        <i class="fas fa-language"></i>
                    </b-btn>
                </b-input-group>
            </b-form-group>
        </div>

        <b-modal v-model="autoCompletionVisible" v-if="autoCompletion" :title="'Suggestions|Suggestions'|tr" scrollable body-class="pt-0" hide-footer>
            <div class="auto-complete">
                <div class="bg-white py-3 auto-complete-search-container">
                    <e-input ref="autoCompleteSearch" v-model="autoCompleteSearch" class="auto-complete-search" state="disabled" debounce @enter="onSubmitAutoCompletion"
                             type="text" :placeholder="'Search|Rechercher'|tr"/>
                </div>
                <b-list-group class="compact auto-completion-list">
                    <b-list-group-item v-for="(item, index) in autoCompletionFiltered" @click="selectAutoCompletion(item)" :key="'complete-' + index" :class="{ 'active': autoCompletionFiltered.length === 1 }">
                        {{item|tr}}
                    </b-list-group-item>
                </b-list-group>
            </div>
        </b-modal>
    </div>
</template>

<script>
    import {translate} from "../helpers/i18n.js";
    import Network from "../helpers/Network.js";

    export default {
        name: `e-translation-input`,
        props: {
            value: {},
            autoCompletion: {type: Array},
            type: { type: String, default: `text` },
            label: { type: String },
            allowEmpty: { type: Boolean, default: true },
            placeholder: {},
            inline: {},
            disabled: {},
            readonly: { type: Boolean, default: false },
            height: { default: `100px` },
            noMargin: {type: Boolean},
            expandedHeight: { default: `100px` },
            size: { type: String },
            state: { type: String },
            maxlength: {type: [String, Number]},
            debounce: {type: Number, default: 0},
            autoTranslate: {type: Boolean, default: false}
        },
        data() {
            return {
                lang: `en`,
                isStringEmpty: isStringEmpty,
                extended: false,
                timeout: null,
                autoCompleteSearch: ``,
                autoCompletionVisible: false
            }
        },
        mounted() {
            this.lang = this.language;
        },
        computed: {
            autoCompletionFiltered() {
                if (this.autoCompletion && !isStringEmpty(this.autoCompleteSearch)) {
                    const regexp = new RegExp(this.autoCompleteSearch, `i`);

                    return this.autoCompletion.filter(item => regexp.test(this.tr(item)));
                } else {
                    return this.autoCompletion;
                }
            },
            isValid() {
                return this.allowEmpty || (!isStringEmpty(this.valueFR) && !isStringEmpty(this.valueEN));
            },
            isFRValid() {
                if (this.state === `disabled`) {
                    return null;
                }
                if (!this.extended) {
                    return this.isValid;
                }

                return this.allowEmpty || !isStringEmpty(this.valueFR);
            },
            isENValid() {
                if (this.state === `disabled`) {
                    return null;
                }
                if (!this.extended) {
                    return this.isValid;
                }

                return this.allowEmpty || !isStringEmpty(this.valueEN);
            },
            valueFR() {
                if (this.value && typeof this.value === `string` && isValidJSON(this.value)) {
                    const value = JSON.parse(this.value);
                    if (value.hasOwnProperty(`fr`))
                        return value.fr;
                }

                if (this.value && typeof this.value === `string` && this.value.indexOf(`|`) >= 0) {
                    return this.value.split(`|`)[1];
                }

                if (!this.value || typeof this.value === `string` || !this.value.hasOwnProperty(`fr`))
                    return ``;

                return this.value.fr;
            },
            valueEN() {
                if (this.value && typeof this.value === `string` && isValidJSON(this.value)) {
                    const value = JSON.parse(this.value);
                    if (value.hasOwnProperty(`en`))
                        return value.en;
                }

                if (this.value && typeof this.value === `string` && this.value.indexOf(`|`) >= 0) {
                    return this.value.split(`|`)[0];
                }

                if (typeof this.value === `string`)
                    return this.value;

                if (!this.value || !this.value.hasOwnProperty(`en`))
                    return ``;

                return this.value.en;
            },
            placeholderEN() {
                if (this.placeholder) {
                    return translate(this.placeholder, `en`) + ` (EN)`;
                } else {
                    return this.tr(`(English)|(Anglais)`);
                }
            },
            placeholderFR() {
                if (this.placeholder) {
                    return translate(this.placeholder, `fr`) + ` (FR)`;
                } else {
                    return this.tr(`(French)|(Français)`);
                }
            }
        },
        methods: {
            changeLanguage() {
                if (this.lang === `fr`) {
                    this.lang = `en`;
                } else {
                    this.lang = `fr`;
                }
            },
            change(lang, val) {
                if (this.timeout)
                    clearTimeout(this.timeout);

                this.timeout = setTimeout(() => {
                    let value = {
                        en: this.valueEN,
                        fr: this.valueFR
                    };
                    value[lang] = val;
                    this.$emit(`input`, value);
                }, this.debounce);
            },
            translateWithGoogle(text, from, to) {
                return Network.post(`/translate`, { text, from, to }, { system_api: true })
                    .then(res => {
                        this.change(to, res.data);
                    })
                    .catch(err => {
                        console.error(err.response.data);
                        return text;
                    });
            },
            showAutoCompletion() {
                this.autoCompletionVisible = true;
                setTimeout(() => {
                    this.$refs.autoCompleteSearch.select();
                }, 150);
            },
            selectAutoCompletion(value) {
                if (value) {
                    this.$emit(`input`, {
                        en: value.en,
                        fr: value.fr
                    });
                    this.autoCompletionVisible = false;
                }
            },
            onSubmitAutoCompletion() {
                if (this.autoCompletionFiltered && this.autoCompletionFiltered.length > 0) {
                    this.selectAutoCompletion(this.autoCompletionFiltered[0]);
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.modal {
    .auto-complete {
        .auto-complete-search-container {
            position: sticky;
            top: 0;
            z-index: 1;
        }

        .auto-completion-list {
            width: 100%;
        }
    }
}
</style>

<style lang="scss" scoped>
    .translate-input {
        position: relative;

        .translate-btn {
            min-width: auto !important;
        }

        //.translate-btn {
        //    display: none !important;
        //    position: absolute;
        //    border-radius: 4px !important;
        //    top: 45px;
        //    left: 0;
        //    max-width: 40px !important;
        //    min-width: 40px !important;
        //    font-size: 1.5rem !important;
        //    padding: 0 !important;
        //}

        .overlay {
            position: fixed;
            background-color: rgba(0, 0, 0, 0.7);
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            z-index: -1;
            opacity: 0;
            transition: opacity 250ms;
            pointer-events: none;
        }

        &.extended {
            .translate-btn {
                display: block !important;
            }

            .overlay {
                z-index: 100;
                opacity: 1;
                pointer-events: all;
                backdrop-filter: blur(5px);
            }

            .input-container {
                z-index: 101;
                position: relative;
            }

            ::v-deep label,
            ::v-deep .form-help,
            ::v-deep .col-form-label {
                color: white !important;
            }
        }

        .dropdown-menu {
            background-color: transparent;
            border: none;
            color: inherit;
            padding: 0;
            width: 100%;
        }

        .flag-container {
            position: relative;
            cursor: pointer;

            .fas {
                position: absolute;
                right: 2px;
                bottom: 2px;
            }

            img {
                max-width: 20px;
                transition: transform 100ms;
            }

            &:hover {
                background-color: #ddd;

                img {
                    transform: scale(1.2);
                }
            }

            &:active {
                background-color: #ccc;

                img {
                    transform: scale(0.85);
                }
            }
        }
    }

    .input-group {
        padding: 0;

        .form-control:first-child {
            border-radius: 0.25rem 0 0 0.25rem;
        }

        .form-control:last-child {
            border-radius: 0 0.25rem 0.25rem 0;
        }
    }
</style>
