<template>
    <vue-select v-model="itemType" :reduce="t => t.type" label="name" :clearable="false" :options="itemTypes" :append-to-body="appendToBody">
        <template #open-indicator>
            <i class="ml-auto fas fa-caret-down"></i>
        </template>
        <template v-slot:selected-option="option">
            <i class="fa-fw float-left pt-1 fas mr-1" :class="option.glyph"/>
            <div>{{ option.name|tr }}</div>
        </template>
        <template v-slot:option="option">
            <div class="menu-option">
                <i class="fa-fw float-left fas pt-1 mr-1" :class="option.glyph"/>
                <div>{{ option.name|tr }}</div>
            </div>
        </template>
    </vue-select>
</template>

<script>
import VueSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

export default {
    name: `select-cms-item-type`,
    components: {VueSelect},
    props: {
        value: {type: String, required: true},
        itemTypes: {type: Array},
        appendToBody: {type: Boolean}
    },
    data() {
        return {
        }
    },
    computed: {
        itemType: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit(`input`, value);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
